import { render, staticRenderFns } from "./AtomicSwaps.vue?vue&type=template&id=481ee66c&scoped=true&"
import script from "./AtomicSwaps.vue?vue&type=script&lang=js&"
export * from "./AtomicSwaps.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481ee66c",
  null
  
)

export default component.exports