var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invest-form"},[(_vm.isLoaded && _vm.isAllowedAccountType)?[_c('form',{staticClass:"app__form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.processInvestment($event)}}},[(!_vm.canUpdateOffer)?_c('message-box',{attrs:{"type":"danger","title":_vm._f("globalize")('invest-form.investment-disabled-title'),"message":_vm._f("globalize")(_vm.investmentDisabledMessageId)}}):_vm._e(),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('select-field',{attrs:{"value":_vm.form.asset.code,"label":_vm._f("globalize")('invest-form.asset-lbl'),"name":"invest-asset","disabled":_vm.formMixin.isConfirmationShown ||
              !_vm.canUpdateOffer || _vm.formMixin.isDisabled},on:{"input":_vm.setAssetByCode,"blur":function($event){return _vm.touchField('form.asset')}}},_vm._l((_vm.quoteAssetListValues),function(asset){return _c('option',{key:asset.code,domProps:{"value":asset.code}},[_vm._v("\n              "+_vm._s(asset.nameAndCode)+"\n            ")])}),0),_c('vue-markdown',{staticClass:"app__form-field-description invest-form__amount-hint",attrs:{"source":_vm._f("globalize")('invest-form.balance-hint',{
              amount: _vm.availableBalance
            })}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('amount-input-field',{attrs:{"white-autofill":"","name":"invest-amount","validation-type":"outgoing","label":_vm._f("globalize")('invest-form.amount-lbl',{
              asset: _vm.form.asset.code
            }),"asset":_vm.form.asset,"readonly":_vm.formMixin.isDisabled,"disabled":_vm.formMixin.isConfirmationShown ||
              !_vm.canUpdateOffer || _vm.formMixin.isDisabled || _vm.isZeroBalance},on:{"change":function($event){return _vm.former.setAttr('amount', _vm.form.amount)},"input":function($event){return _vm.touchField('form.amount')}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('p',{staticClass:"app__form-field-description"},[(_vm.isAssetPairPriceLoaded)?_c('vue-markdown',{staticClass:"app__form-field-description invest-form__amount-hint",attrs:{"source":_vm._f("globalize")('invest-form.converted-amount-hint',{
                amount: {
                  value: _vm.convertedAmount,
                  currency: _vm.sale.defaultQuoteAsset
                }
              })}}):(_vm.isPriceLoadFailed)?[_vm._v("\n              "+_vm._s(_vm._f("globalize")('invest-form.converting-error-msg'))+"\n            ")]:[_vm._v("\n              "+_vm._s(_vm._f("globalize")('invest-form.loading-msg'))+"\n            ")]],2)],1)]),(_vm.currentInvestment.quoteAmount)?_c('vue-markdown',{staticClass:"invest-form__current-investment",attrs:{"source":_vm._f("globalize")('invest-form.current-investment',{
          amount: {
            value: _vm.currentInvestment.quoteAmount,
            currency: _vm.currentInvestment.quoteAsset.id
          }
        })}}):_vm._e(),_c('transition',{attrs:{"name":"app__fade-in"}},[(_vm.isFeesLoaded)?_c('div',{staticClass:"invest-form__fee-box"},[_c('fees-renderer',{attrs:{"fees-collection":_vm.fees}})],1):_vm._e()]),_c('div',{staticClass:"app__form-actions"},[(_vm.formMixin.isConfirmationShown)?_c('form-confirmation',{attrs:{"message":_vm._f("globalize")('invest-form.recheck-form-msg'),"ok-button":_vm._f("globalize")('invest-form.invest-btn'),"is-pending":_vm.isSubmitting},on:{"cancel":function($event){return _vm.hideConfirmation()},"ok":_vm.submit}}):_vm._e(),(_vm.currentInvestment.id &&
            !_vm.formMixin.isConfirmationShown)?[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised invest-form__submit-btn",attrs:{"type":"button","disabled":_vm.formMixin.isDisabled || !_vm.canSubmit},on:{"click":_vm.processInvestment}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('invest-form.update-offer-btn'))+"\n          ")]),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-flat",attrs:{"type":"button","disabled":_vm.formMixin.isDisabled || !_vm.canUpdateOffer},on:{"click":_vm.cancelOffer}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('invest-form.cancel-offer-btn'))+"\n          ")])]:[(!_vm.formMixin.isConfirmationShown)?_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised",attrs:{"click":"submit","disabled":_vm.formMixin.isDisabled || !_vm.canSubmit}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('invest-form.invest-btn'))+"\n          ")]):_vm._e()]],2)],1)]:(_vm.isLoadingFailed && _vm.isAllowedAccountType)?[_c('p',[_vm._v("\n      "+_vm._s(_vm._f("globalize")('invest-form.loading-error-msg'))+"\n    ")])]:(!_vm.isAllowedAccountType)?[_c('no-data-message',{attrs:{"icon-name":"alert-circle","title":_vm._f("globalize")((
          _vm.isAccountUsVerified
            ? 'invest-form.requires-us-accreditation-title'
            : 'invest-form.requires-verification-title'
        )),"message":_vm._f("globalize")((
          _vm.isAccountUsVerified
            ? 'invest-form.requires-us-accreditation-desc'
            : 'invest-form.requires-verification-desc'
        ))}})]:[_c('invest-form-skeleton-loader')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }