var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',[_c('tr',{staticClass:"attributes-viewer__table-row"},[_c('td',{staticClass:"attributes-viewer__table-cell"},[_vm._v("\n      "+_vm._s(_vm._f("globalize")('movements-history.asset-pair-lbl'))+"\n    ")]),_c('td',{staticClass:"attributes-viewer__table-cell"},[_vm._v("\n      "+_vm._s(_vm._f("globalize")('movements-history.asset-pair-value',{
          base: _vm.operationDetails.baseAssetCode,
          quote: _vm.operationDetails.quoteAssetCode,
        }))+"\n    ")])]),_c('tr',{staticClass:"attributes-viewer__table-row"},[_c('td',{staticClass:"attributes-viewer__table-cell"},[_vm._v("\n      "+_vm._s(_vm._f("globalize")('movements-history.physical-price-lbl'))+"\n    ")]),_c('td',{staticClass:"attributes-viewer__table-cell"},[_vm._v("\n      "+_vm._s(_vm._f("formatMoney")({
          value: _vm.operationDetails.physicalPrice,
          currency: _vm.operationDetails.quoteAssetCode
        }))+"\n    ")])]),_c('tr',{staticClass:"attributes-viewer__table-row"},[_c('td',{staticClass:"attributes-viewer__table-cell"},[_vm._v("\n      "+_vm._s(_vm._f("globalize")('movements-history.current-price-lbl'))+"\n    ")]),_c('td',{staticClass:"attributes-viewer__table-cell"},[_vm._v("\n      "+_vm._s(_vm._f("formatMoney")({
          value: _vm.operationDetails.currentPrice,
          currency: _vm.operationDetails.quoteAssetCode
        }))+"\n    ")])]),_c('tr',{staticClass:"attributes-viewer__table-row"},[_c('td',{staticClass:"attributes-viewer__table-cell"},[(_vm.isTradeable)?[_vm._v("\n        "+_vm._s(_vm._f("globalize")('movements-history.tradeable-lbl'))+"\n      ")]:[_vm._v("\n        "+_vm._s(_vm._f("globalize")('movements-history.not-tradeable-lbl'))+"\n      ")]],2)]),_c('tr',{staticClass:"attributes-viewer__table-row"},[_c('td',{staticClass:"attributes-viewer__table-cell"},[(_vm.isPhysicalPriceRestricted)?[_vm._v("\n        "+_vm._s(_vm._f("globalize")('movements-history.restricted-on-ph-price-lbl'))+"\n      ")]:[_vm._v("\n        "+_vm._s(_vm._f("globalize")('movements-history.not-restricted-on-ph-price-lbl'))+"\n      ")]],2)]),_c('tr',{staticClass:"attributes-viewer__table-row"},[_c('td',{staticClass:"attributes-viewer__table-cell"},[(_vm.isCurrentPriceRestricted)?[_vm._v("\n        "+_vm._s(_vm._f("globalize")('movements-history.restricted-on-cur-price-lbl'))+"\n      ")]:[_vm._v("\n        "+_vm._s(_vm._f("globalize")('movements-history.not-restricted-on-cur-price-lbl'))+"\n      ")]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }