var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"atomic-swap-form"},[(!_vm.atomicSwapBidRecord.address)?_c('form',{staticClass:"app__form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();_vm.isFormValid() && _vm.showConfirmation()}}},[_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('select-field',{staticClass:"app__select",attrs:{"label":_vm._f("globalize")('atomic-swap-form.asset-in-which-buying'),"name":"atomic-swap-quote-asset","value":_vm.form.quoteAsset,"disabled":_vm.formMixin.isDisabled},on:{"input":_vm.setQuoteAsset}},_vm._l((_vm.quoteAssets),function(asset){return _c('option',{key:asset.code,domProps:{"value":asset.code}},[_vm._v("\n            "+_vm._s(asset.nameAndCode)+"\n          ")])}),0)],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"atomic-swap-amount","type":"number","error-message":_vm.getFieldErrorMessage(
            'form.amount',
            {
              from: _vm.config.MIN_AMOUNT,
              to: _vm.atomicSwap.availableAmount,
            }),"white-autofill":"","label":_vm._f("globalize")('atomic-swap-form.amount',{
            asset: _vm.atomicSwap.baseAsset
          }),"disabled":_vm.formMixin.isDisabled},on:{"blur":function($event){return _vm.touchField('form.amount')}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('p',{staticClass:"app__form-field-description"},[_vm._v("\n          "+_vm._s(_vm._f("globalize")('atomic-swap-form.available',{
            amount: _vm.atomicSwap.availableAmount,
            asset: _vm.atomicSwap.baseAsset,
          }))+"\n        ")])],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('readonly-field',{staticClass:"atomic-swap-form__price",attrs:{"label":_vm._f("globalize")('atomic-swap-form.price'),"value":_vm._f("formatMoney")({
            value: _vm.quoteAssetPrice,
            currency: _vm.form.quoteAsset,
          })}}),_c('readonly-field',{attrs:{"label":_vm._f("globalize")('atomic-swap-form.total-price'),"value":_vm._f("formatMoney")({
            value: _vm.totalPrice,
            currency: _vm.form.quoteAsset,
          })}})],1)]),_c('div',{staticClass:"app__form-actions"},[(_vm.formMixin.isConfirmationShown)?_c('form-confirmation',{attrs:{"is-pending":_vm.isSubmitting},on:{"ok":function($event){return _vm.submit()},"cancel":function($event){return _vm.hideConfirmation()}}}):_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised atomic-swap-form__btn",attrs:{"type":"submit","disabled":_vm.formMixin.isDisabled}},[[_vm._v("\n          "+_vm._s(_vm._f("globalize")('atomic-swap-form.buy'))+"\n        ")]],2)],1)]):_c('address-viewer',{attrs:{"asset-code":_vm.atomicSwapBidRecord.quoteAssetCode,"amount":_vm.atomicSwapBidRecord.amount,"address":_vm.atomicSwapBidRecord.address,"end-time":_vm.atomicSwapBidRecord.endTime}}),(_vm.isFailedLoadAtomicSwapBidRecord)?_c('p',[_vm._v("\n    "+_vm._s(_vm._f("globalize")('atomic-swap-form.error-msg'))+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }