var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stellar-section"},[_c('h3',{staticClass:"app__form-subheading"},[_vm._v("\n    "+_vm._s(_vm._f("globalize")('asset-form.stellar'))+"\n  ")]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('tick-field',{attrs:{"disabled":_vm.isDisabled},on:{"input":_vm.onIntegrationToggle},model:{value:(_vm.form.isIntegrated),callback:function ($$v) {_vm.$set(_vm.form, "isIntegrated", $$v)},expression:"form.isIntegrated"}},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('asset-form.integration-with-stellar'))+"\n      ")])],1)]),(_vm.form.isIntegrated)?[_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('tick-field',{attrs:{"disabled":_vm.isDisabled},on:{"input":function($event){_vm.former
            .setAttr('stellarIntegration.isDepositable', _vm.form.isDepositable)}},model:{value:(_vm.form.isDepositable),callback:function ($$v) {_vm.$set(_vm.form, "isDepositable", $$v)},expression:"form.isDepositable"}},[_vm._v("\n          "+_vm._s(_vm._f("globalize")('asset-form.deposit-lbl'))+"\n        ")])],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('tick-field',{attrs:{"disabled":_vm.isDisabled},on:{"input":function($event){_vm.former
            .setAttr('stellarIntegration.isWithdrawable', _vm.form.isWithdrawable)}},model:{value:(_vm.form.isWithdrawable),callback:function ($$v) {_vm.$set(_vm.form, "isWithdrawable", $$v)},expression:"form.isWithdrawable"}},[_vm._v("\n          "+_vm._s(_vm._f("globalize")('asset-form.withdraw-lbl'))+"\n        ")])],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('select-field',{attrs:{"name":"create-asset-type","label":_vm._f("globalize")('asset-form.stellar-type-lbl'),"error-message":_vm.getFieldErrorMessage('form.stellarAssetType'),"disabled":_vm.isDisabled},on:{"input":function($event){_vm.touchField('form.stellarAssetType');
                  // eslint-disable-next-line max-len
                  _vm.former.setAttr('stellarIntegration.assetType', _vm.form.stellarAssetType)}},model:{value:(_vm.form.stellarAssetType),callback:function ($$v) {_vm.$set(_vm.form, "stellarAssetType", $$v)},expression:"form.stellarAssetType"}},[_c('option',{domProps:{"value":_vm.STELLAR_TYPES.creditAlphanum4}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('asset-form.alphanumeric-4-lbl'))+"\n          ")]),_c('option',{domProps:{"value":_vm.STELLAR_TYPES.creditAlphanum12}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('asset-form.alphanumeric-12-lbl'))+"\n          ")]),_c('option',{domProps:{"value":_vm.STELLAR_TYPES.native}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('asset-form.native-lbl'))+"\n          ")])])],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"white-autofill":"","name":"create-asset-stellar-code","label":_vm._f("globalize")('asset-form.code-lbl'),"error-message":_vm.getFieldErrorMessage(
            'form.stellarAssetCode',
            {
              minLength: _vm.CODE_MIN_LEN_MAP[_vm.form.stellarAssetType],
              maxLength: _vm.CODE_MAX_LEN_MAP[_vm.form.stellarAssetType]
            }
          ),"disabled":_vm.isDisabled ||
            _vm.form.stellarAssetType === _vm.STELLAR_TYPES.native,"minlength":_vm.CODE_MIN_LEN_MAP[_vm.form.stellarAssetType],"maxlength":_vm.CODE_MAX_LEN_MAP[_vm.form.stellarAssetType]},on:{"blur":function($event){return _vm.touchField('form.stellarAssetCode')},"change":function($event){_vm.former
            .setAttr('stellarIntegration.assetCode', _vm.form.stellarAssetCode)}},model:{value:(_vm.form.stellarAssetCode),callback:function ($$v) {_vm.$set(_vm.form, "stellarAssetCode", $$v)},expression:"form.stellarAssetCode"}})],1)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }