var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issuance-section"},[_c('h3',{staticClass:"issuance-section__subheading app__form-subheading"},[_vm._v("\n    "+_vm._s(_vm._f("globalize")('asset-form.issuance-subheading'))+"\n  ")]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('tick-field',{staticClass:"issuance-section__pre-issuance-tick-field",attrs:{"disabled":_vm.isDisabled},on:{"input":_vm.onMaxAmountToggle},model:{value:(_vm.form.isMaxAmountRestricted),callback:function ($$v) {_vm.$set(_vm.form, "isMaxAmountRestricted", $$v)},expression:"form.isMaxAmountRestricted"}},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('asset-form.restrict-max-amount-check'))+"\n      ")])],1)]),(_vm.form.isMaxAmountRestricted)?[_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"white-autofill":"","type":"number","min":_vm.$config.MIN_AMOUNT,"max":_vm.$config.MAX_AMOUNT,"step":_vm.$config.MIN_AMOUNT,"name":"create-asset-max-issuance-amount","label":_vm._f("globalize")('asset-form.max-issuance-amount-lbl'),"error-message":_vm.getFieldErrorMessage('form.maxIssuanceAmount', {
            from: _vm.$config.MIN_AMOUNT,
            to: _vm.$config.MAX_AMOUNT,
          }),"disabled":_vm.isDisabled},on:{"blur":function($event){return _vm.touchField('form.maxIssuanceAmount')},"change":function($event){_vm.former
            .setAttr('maxIssuanceAmount', _vm.form.maxIssuanceAmount)}},model:{value:(_vm.form.maxIssuanceAmount),callback:function ($$v) {_vm.$set(_vm.form, "maxIssuanceAmount", $$v)},expression:"form.maxIssuanceAmount"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('tick-field',{staticClass:"issuance-section__pre-issuance-tick-field",attrs:{"disabled":_vm.isDisabled},on:{"input":_vm.onPreIssuanceToggle},model:{value:(_vm.form.isPreIssuanceEnabled),callback:function ($$v) {_vm.$set(_vm.form, "isPreIssuanceEnabled", $$v)},expression:"form.isPreIssuanceEnabled"}},[_vm._v("\n          "+_vm._s(_vm._f("globalize")('asset-form.additional-issuance-check'))+"\n        ")])],1)])]:_vm._e(),(_vm.form.isMaxAmountRestricted && _vm.form.isPreIssuanceEnabled)?[_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('div',{staticClass:"issuance-section__buttoned-field-wrp"},[_c('input-field',{attrs:{"white-autofill":"","name":"create-asset-pre-issuance-asset-signer","label":_vm._f("globalize")('asset-form.pre-issuance-signer-lbl'),"error-message":_vm.getFieldErrorMessage(
              'form.preIssuanceAssetSigner'
            ),"disabled":_vm.isDisabled},on:{"blur":function($event){return _vm.touchField('form.preIssuanceAssetSigner')},"change":function($event){_vm.former
              .setAttr('preIssuanceAssetSigner', _vm.form.preIssuanceAssetSigner)}},model:{value:(_vm.form.preIssuanceAssetSigner),callback:function ($$v) {_vm.$set(_vm.form, "preIssuanceAssetSigner", $$v)},expression:"form.preIssuanceAssetSigner"}}),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-flat",attrs:{"type":"button","disabled":_vm.isDisabled},on:{"click":_vm.populatePreIssuanceSigner}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('asset-form.use-my-account-id-btn'))+"\n          ")])],1),(_vm.form.preIssuanceAssetSigner === _vm.accountId)?_c('vue-markdown',{staticClass:"issuance-section__pre-iss-disclaimer",attrs:{"source":_vm._f("globalize")('asset-form.pre-issuance-disclaimer')}}):_vm._e()],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"white-autofill":"","type":"number","min":_vm.$config.MIN_AMOUNT,"max":_vm.form.maxIssuanceAmount,"step":_vm.$config.MIN_AMOUNT,"name":"create-asset-initial-preissued-amount","label":_vm._f("globalize")('asset-form.preissued-amount-lbl'),"error-message":_vm.getFieldErrorMessage(
            'form.initialPreissuedAmount',
            { from: 0, to: _vm.form.maxIssuanceAmount }
          ),"disabled":_vm.isDisabled},on:{"blur":function($event){return _vm.touchField('form.initialPreissuedAmount')},"change":function($event){_vm.former
            .setAttr('initialPreissuedAmount', _vm.form.initialPreissuedAmount)}},model:{value:(_vm.form.initialPreissuedAmount),callback:function ($$v) {_vm.$set(_vm.form, "initialPreissuedAmount", $$v)},expression:"form.initialPreissuedAmount"}}),_c('router-link',{staticClass:"issuance-section__pre-iss-guide-link",attrs:{"to":_vm.$routes.preIssuanceGuide,"target":"_blank","rel":"noopener"}},[_vm._v("\n          "+_vm._s(_vm._f("globalize")('asset-form.pre-issuance-guide-link'))+"\n          "),_c('i',{staticClass:"mdi mdi-launch"})])],1)])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }