var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"app-form limits-update-form"},[_c('h3',{staticClass:"limits-form__form-subheading app__form-subheading"},[_vm._v("\n    "+_vm._s(_vm._f("globalize")('limits-form.request-new-limits'))+"\n  ")]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"limit-daily-out","type":"number","min":_vm.minValidDailyOutValue,"max":_vm.config.MAX_AMOUNT,"label":_vm._f("globalize")('limits-form.daily-limit-lbl'),"readonly":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(
          'form.dailyOut',
          {
            maxValue: _vm.config.MAX_AMOUNT,
            minValue: _vm.minValidDailyOutValue,
            quantity: _vm.config.DECIMAL_POINTS,
          }
        )},on:{"change":function($event){return _vm.former.setAttr('dailyOut', _vm.form.dailyOut)},"blur":function($event){return _vm.touchField('form.dailyOut')}},model:{value:(_vm.form.dailyOut),callback:function ($$v) {_vm.$set(_vm.form, "dailyOut", $$v)},expression:"form.dailyOut"}})],1),_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"limit-weekly-out","type":"number","min":_vm.minValidWeeklyOutValue,"max":_vm.config.MAX_AMOUNT,"label":_vm._f("globalize")('limits-form.weekly-limit-lbl'),"readonly":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(
          'form.weeklyOut',
          {
            maxValue: _vm.config.MAX_AMOUNT,
            minValue: _vm.minValidWeeklyOutValue,
            quantity: _vm.config.DECIMAL_POINTS,
          }
        )},on:{"change":function($event){return _vm.former.setAttr('weeklyOut', _vm.form.weeklyOut)},"blur":function($event){return _vm.touchField('form.weeklyOut')}},model:{value:(_vm.form.weeklyOut),callback:function ($$v) {_vm.$set(_vm.form, "weeklyOut", $$v)},expression:"form.weeklyOut"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"limit-monthly-out","type":"number","min":_vm.minValidMonthlyOutValue,"max":_vm.config.MAX_AMOUNT,"label":_vm._f("globalize")('limits-form.monthly-limit-lbl'),"readonly":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(
          'form.monthlyOut',
          {
            maxValue: _vm.config.MAX_AMOUNT,
            minValue: _vm.minValidMonthlyOutValue,
            quantity: _vm.config.DECIMAL_POINTS,
          }
        )},on:{"change":function($event){return _vm.former.setAttr('monthlyOut', _vm.form.monthlyOut)},"blur":function($event){return _vm.touchField('form.monthlyOut')}},model:{value:(_vm.form.monthlyOut),callback:function ($$v) {_vm.$set(_vm.form, "monthlyOut", $$v)},expression:"form.monthlyOut"}})],1),_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"limit-annual-out","type":"number","min":_vm.minValidAnnualOutValue,"max":_vm.config.MAX_AMOUNT,"label":_vm._f("globalize")('limits-form.annual-limit-lbl'),"readonly":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(
          'form.annualOut',
          {
            maxValue: _vm.config.MAX_AMOUNT,
            minValue: _vm.minValidAnnualOutValue,
            quantity: _vm.config.DECIMAL_POINTS,
          }
        )},on:{"change":function($event){return _vm.former.setAttr('annualOut', _vm.form.annualOut)},"blur":function($event){return _vm.touchField('form.annualOut')}},model:{value:(_vm.form.annualOut),callback:function ($$v) {_vm.$set(_vm.form, "annualOut", $$v)},expression:"form.annualOut"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('textarea-field',{attrs:{"name":"limit-note","label":_vm._f("globalize")('limits-form.note-lbl'),"maxlength":_vm.formNoteMaxLength,"readonly":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage('form.note')},on:{"input":function($event){return _vm.former.setAttr('note', _vm.form.note)},"blur":function($event){return _vm.touchField('form.note')}},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)]),_c('div',{staticClass:"app__form-actions"},[(!_vm.formMixin.isConfirmationShown)?_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised",attrs:{"type":"button","disabled":_vm.formMixin.isDisabled},on:{"click":_vm.tryToSubmit}},[_vm._v("\n      "+_vm._s(_vm._f("globalize")('limits-form.send-request-btn'))+"\n    ")]):_c('form-confirmation',{attrs:{"is-pending":_vm.isRequestCreating,"message":_vm._f("globalize")('transfer-form.recheck-form'),"ok-button":_vm._f("globalize")('transfer-form.submit-btn')},on:{"cancel":_vm.hideConfirmation,"ok":_vm.submit}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }