var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.isFormValid() && _vm.showConfirmation()}}},[_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('select-field',{attrs:{"name":"trade-offer-base-asset","disabled":_vm.formMixin.isDisabled,"label":_vm._f("globalize")(_vm.baseAssetLabelTranslationId)},model:{value:(_vm.form.assetCode),callback:function ($$v) {_vm.$set(_vm.form, "assetCode", $$v)},expression:"form.assetCode"}},_vm._l((_vm.accountAssets),function(asset){return _c('option',{key:asset,domProps:{"value":asset}},[_vm._v("\n          "+_vm._s(asset)+"\n        ")])}),0)],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"trade-offer-price","type":"number","min":0,"max":_vm.config.MAX_AMOUNT,"step":_vm.config.MIN_AMOUNT,"label":_vm._f("globalize")('create-trade-offer-form.price-lbl',{
            baseAsset: _vm.form.assetCode,
            quoteAsset: _vm.assetPair.quote
          }),"error-message":_vm.getFieldErrorMessage(
          'form.price', {
            from: _vm.config.MIN_AMOUNT,
            to: _vm.config.MAX_AMOUNT,
            available: _vm.quoteAssetBalance
          }
        ),"disabled":_vm.formMixin.isDisabled},on:{"blur":function($event){return _vm.touchField('form.price')}},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.price"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"trade-offer-amount","type":"number","min":0,"max":_vm.config.MAX_AMOUNT,"step":_vm.config.MIN_AMOUNT,"label":_vm._f("globalize")('create-trade-offer-form.amount-lbl',{
          asset: _vm.form.assetCode
        }),"error-message":_vm.getFieldErrorMessage(
          'form.amount',
          {
            available: _vm.baseAssetBalance,
            from: _vm.config.MIN_AMOUNT,
            to: _vm.config.MAX_AMOUNT,
          }
        ),"disabled":_vm.formMixin.isDisabled},on:{"blur":function($event){return _vm.touchField('form.amount')}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.amount"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[(_vm.isFeesLoaded)?[_c('readonly-field',{attrs:{"label":_vm._f("globalize")('create-trade-offer-form.total-amount-lbl'),"value":_vm._f("formatMoney")({
            value: _vm.quoteAmount,
            currency: _vm.assetPair.quote,
          }),"error-message":_vm.getFieldErrorMessage(
            'quoteAmount',
            {
              available: _vm.quoteAssetBalance,
              from: _vm.config.MIN_AMOUNT,
              to: _vm.config.MAX_AMOUNT,
            }
          )}}),_c('fees-renderer',{staticClass:"create-trade-offer-form__fees",attrs:{"fees-collection":_vm.fees}})]:[_c('loader',{attrs:{"message-id":"create-trade-offer-form.loading-msg"}})]],2)]),(_vm.formMixin.isConfirmationShown)?[_c('form-confirmation',{staticClass:"app__form-confirmation",attrs:{"is-pending":_vm.isOfferCreating},on:{"cancel":_vm.hideConfirmation,"ok":_vm.submit}})]:[_c('div',{staticClass:"app__form-actions"},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised create-trade-offer-form__btn",attrs:{"type":"submit","disabled":_vm.formMixin.isDisabled || !_vm.isFeesLoaded}},[(_vm.isBuy)?[_vm._v("\n          "+_vm._s(_vm._f("globalize")('create-trade-offer-form.buy-btn'))+"\n        ")]:[_vm._v("\n          "+_vm._s(_vm._f("globalize")('create-trade-offer-form.sell-btn'))+"\n        ")]],2)])]],2):_c('skeleton-loader-offer-form')}
var staticRenderFns = []

export { render, staticRenderFns }