var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"app__form create-poll-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();_vm.isFormValid() && _vm.showConfirmation()}}},[_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('textarea-field',{attrs:{"white-autofill":"","name":"create-poll-question","label":_vm._f("globalize")('create-poll-form.question-lbl'),"disabled":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage('form.question'),"maxlength":_vm.QUESTION_MAX_LENGTH},on:{"blur":function($event){return _vm.touchField('form.question')},"input":function($event){return _vm.former.setAttr('question', _vm.form.question)}},model:{value:(_vm.form.question),callback:function ($$v) {_vm.$set(_vm.form, "question", $$v)},expression:"form.question"}})],1)]),_vm._l((_vm.form.choices),function(choice,index){return _c('div',{key:index,staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('div',{staticClass:"create-poll-form__choice-wrp"},[_c('div',{staticClass:"create-poll-form__choice"},[_c('input-field',{attrs:{"white-autofill":"","name":'create-poll-description',"label":_vm._f("globalize")('create-poll-form.description-lbl',{
              number: index + 1
            }),"disabled":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(("\n              form.choices[" + index + "].description\n            "))},on:{"blur":function($event){return _vm.touchField(("form.choices[" + index + "].description"))}},model:{value:(_vm.form.choices[index].description),callback:function ($$v) {_vm.$set(_vm.form.choices[index], "description", $$v)},expression:"form.choices[index].description"}}),(_vm.canDeleteChoice(index + 1))?_c('button',{staticClass:"create-poll-form__delete-choice-btn",attrs:{"type":"button","disabled":_vm.formMixin.isDisabled},on:{"click":function($event){return _vm.deleteChoice(index)}}},[_c('i',{staticClass:"mdi mdi-minus-circle-outline create-poll-form__delete-choice-icon"})]):_vm._e()],1),(_vm.canAddChoice(index + 1))?_c('div',{staticClass:"create-poll-form__add-choice-wrp"},[_c('p',{staticClass:"create-poll-form__add-choice"},[_c('button',{staticClass:"create-poll-form__add-choice-btn",attrs:{"disabled":_vm.formMixin.isDisabled},on:{"click":function($event){return _vm.addChoice(choice)}}},[_vm._v("\n              "+_vm._s(_vm._f("globalize")('create-poll-form.add-choice-btn'))+"\n            ")])])]):_vm._e()])])])}),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('date-field',{attrs:{"name":"create-poll-form-start-time","enable-time":true,"label":_vm._f("globalize")('create-poll-form.start-time-lbl'),"disabled":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(
          'form.startTime'
        )},on:{"blur":function($event){return _vm.touchField('form.startTime')},"input":function($event){return _vm.former.setAttr('startTime', _vm.form.startTime)}},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('date-field',{attrs:{"enable-time":true,"disable-before":_vm.yesterday,"name":"create-poll-end-time","label":_vm._f("globalize")('create-poll-form.end-time-lbl'),"disabled":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(
          'form.endTime', { minDate: _vm.form.startTime || _vm.getCurrentDate() }
        )},on:{"blur":function($event){return _vm.touchField('form.endTime')},"input":function($event){return _vm.former.setAttr('endTime', _vm.form.endTime)}},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('select-field',{attrs:{"name":"create-poll-permission-type","label":_vm._f("globalize")('create-poll-form.permission-type-lbl'),"disabled":_vm.formMixin.isDisabled,"error-message":_vm.getFieldErrorMessage(
          'form.permissionType'
        )},on:{"blur":function($event){return _vm.touchField('form.permissionType')},"input":function($event){return _vm.former.setAttr('permissionType', _vm.form.permissionType)}},model:{value:(_vm.form.permissionType),callback:function ($$v) {_vm.$set(_vm.form, "permissionType", $$v)},expression:"form.permissionType"}},_vm._l((_vm.pollPermissionTypes),function(pollPermissionType){return _c('option',{key:pollPermissionType.value,domProps:{"value":pollPermissionType.value}},[_vm._v("\n          "+_vm._s(_vm._f("globalize")(pollPermissionType.labelTranslationId))+"\n        ")])}),0)],1)]),_c('div',{staticClass:"app__form-actions"},[(_vm.formMixin.isConfirmationShown)?_c('form-confirmation',{attrs:{"is-pending":_vm.isSubmitting},on:{"ok":function($event){return _vm.submit()},"cancel":function($event){return _vm.hideConfirmation()}}}):_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised create-poll-form__btn",attrs:{"type":"submit","disabled":_vm.formMixin.isDisabled}},[[_vm._v("\n        "+_vm._s(_vm._f("globalize")('create-poll-form.create-btn'))+"\n      ")]],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }