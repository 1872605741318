var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"asset-selector"},[[_c('div',{staticClass:"asset-selector__wrapper"},[_c('div',{staticClass:"asset-selector__select"},[(_vm.imgUrl === null || !_vm.currentAssetCode ||
            _vm.assets.length === 0)?_c('skeleton-loader',{staticClass:"asset-selector__select-picture",attrs:{"template":"bigIcon"}}):_c('div',{staticClass:"asset-selector__select-picture"},[(_vm.imgUrl)?_c('img',{staticClass:"asset-selector__select-picture-tag",attrs:{"src":_vm.imgUrl}}):_vm._e(),(_vm.currentAssetCode && !_vm.imgUrl)?_c('p',{staticClass:"asset-selector__asset-code-abbr"},[_vm._v("\n            "+_vm._s(_vm._f("abbreviate")(_vm.currentAssetCode))+"\n          ")]):_vm._e()]),_c('div',[(_vm.imgUrl === null || !_vm.currentAssetCode ||
              _vm.assets.length === 0)?_c('skeleton-loader',{staticClass:"app__select",attrs:{"template":"bigString"}}):_c('select-field',{key:_vm.currentAssetForSelect.code,staticClass:"app__select app__select--no-border",attrs:{"value":_vm.currentAssetForSelect.code},on:{"input":function($event){return _vm.$emit(_vm.EVENTS.assetChange, $event)}}},_vm._l((_vm.assetsList),function(asset){return _c('option',{key:asset.code,domProps:{"value":asset.code}},[_vm._v("\n              "+_vm._s(asset.nameAndCode)+"\n            ")])}),0)],1)],1)]),[_c('div',{staticClass:"asset-selector__wrapper asset-selector__wrapper--values"},[_c('div',{staticClass:"asset-selector__asset-available"},[_c('div',{staticClass:"asset-selector__asset-value"},[(!_vm.currentAssetCode)?_c('skeleton-loader',{attrs:{"template":"bigString"}}):_c('span',{staticClass:"asset-selector__asset-value-main"},[_vm._v("\n              "+_vm._s(_vm._f("formatMoney")({
                  value: _vm.currentAssetBalanceDetails.balance,
                  currency: _vm.currentAssetCode
                }))+"\n            ")])],1),_c('div',{staticClass:"asset-selector__asset-subvalue"},[(!_vm.currentAssetCode &&
                _vm.currentAssetBalanceDetails.convertedBalance)?_c('skeleton-loader',{attrs:{"template":"bigString"}}):(_vm.currentAssetBalanceDetails.convertedBalance)?_c('div',{staticClass:"asset-selector__asset-converted"},[_c('span',{staticClass:"asset-selector__asset-value-secondary"},[_vm._v("\n                "+_vm._s(_vm._f("formatMoney")({
                    value: _vm.currentAssetBalanceDetails.convertedBalance,
                    currency: _vm.currentAssetBalanceDetails.convertedToAsset
                  }))+"\n              ")])]):_vm._e()],1)])])],(_vm.assets.length && !_vm.currentAssetCode)?[_c('no-data-message',{attrs:{"title":_vm._f("globalize")('dashboard.no-assets-in-your-wallet'),"message":_vm._f("globalize")('dashboard.here-will-be-the-assets')}})]:_vm._e()],(_vm.isLoadingFailed)?[_c('p',[_vm._v("\n      "+_vm._s(_vm._f("globalize")('dashboard.loading-error-msg'))+"\n    ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }