var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-field",class:{
    'input-field--error': _vm.errorMessage,
    'input-field--monospaced': _vm.monospaced,
    'input-field--readonly': _vm.$attrs.readonly || _vm.$attrs.readonly === '',
    'input-field--disabled': _vm.$attrs.disabled,
    'input-field--pwd-toggle-present': _vm.isPasswordType,
  }},[_c('input',_vm._g(_vm._b({staticClass:"input-field__input",class:{
      'input-field__input--autofill-white': _vm.whiteAutofill
    },attrs:{"type":_vm.isPasswordType && _vm.isPasswordShown ? 'text' : _vm.type,"placeholder":_vm.$attrs.placeholder || ' ',"tabindex":_vm.$attrs.readonly || _vm.$attrs.readonly === ''
      ? -1
      : _vm.$attrs.tabindex},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.isPasswordType)?_c('button',{staticClass:"input-field__password-toggle",class:{
      'input-field__password-toggle--autofill-white': _vm.whiteAutofill
    },attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){_vm.isPasswordShown = !_vm.isPasswordShown}}},[_c('i',{staticClass:"mdi input-field__password-toggle-icon",class:_vm.isPasswordShown ? 'mdi-eye-off-outline' : 'mdi-eye-outline'})]):_vm._e(),_c('span',{staticClass:"input-field__label"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n\n    "),(_vm.isCapsLockOn)?[_vm._v("\n      ("+_vm._s(_vm._f("globalize")('input-field.caps-lock-warning'))+")\n    ")]:_vm._e()],2),_c('transition',{attrs:{"name":"input-field__err-transition"}},[(_vm.errorMessage)?_c('p',{staticClass:"input-field__err-mes"},[_vm._v("\n      "+_vm._s(_vm.errorMessage)+"\n    ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }