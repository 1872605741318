var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transfer app__page-content-wrp"},[(_vm.isLoaded)?[(!_vm.transferableBalancesAssets.length)?[_c('h2',{staticClass:"app__page-heading"},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('transfer-form.no-assets-heading'))+"\n      ")]),_c('p',{staticClass:"app__page-explanations app__page-explanations--secondary"},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('transfer-form.no-assets'))+"\n      ")]),_c('router-link',{staticClass:"app__button-raised transfer-form__discover-asset-btn",attrs:{"to":_vm.vueRoutes.assets.name,"tag":"button"}},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('transfer-form.discover-assets-btn'))+"\n      ")])]:[_c('form',{attrs:{"id":"transfer-form"},on:{"submit":function($event){$event.preventDefault();return _vm.loadFees($event)}}},[_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('select-field',{attrs:{"name":"transfer-asset","value":_vm.form.asset.code,"label":_vm._f("globalize")('transfer-form.asset-lbl'),"readonly":_vm.formMixin.isDisabled},on:{"input":_vm.setAsset,"change":function($event){return _vm.former.setAttr('assetCode', _vm.form.asset.code)}}},_vm._l((_vm.transferableBalancesAssets),function(asset){return _c('option',{key:asset.code,domProps:{"value":asset.code}},[_vm._v("\n                "+_vm._s(asset.nameAndCode)+"\n              ")])}),0),(_vm.form.asset.code)?[_c('p',{staticClass:"app__form-field-description",class:{ 'app__form-field-description--error': !_vm.balance }},[_vm._v("\n                "+_vm._s(_vm._f("globalize")('transfer-form.balance',{
                    amount: _vm.balance,
                    asset: _vm.form.asset.code,
                    available: _vm.balance
                  }))+"\n              ")])]:_vm._e()],2)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('amount-input-field',{attrs:{"name":"transfer-amount","validation-type":"outgoing","label":_vm._f("globalize")('transfer-form.amount-lbl'),"asset":_vm.form.asset,"is-max-button-shown":"","readonly":_vm.formMixin.isDisabled,"disabled":!_vm.balance},on:{"change":function($event){return _vm.former.setAttr('amount', _vm.form.amount)}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('input-field',{attrs:{"name":"transfer-recipient","label":_vm._f("globalize")('transfer-form.recipient-lbl'),"error-message":_vm.getFieldErrorMessage('form.recipient'),"disabled":_vm.formMixin.isDisabled || !_vm.balance},on:{"change":function($event){return _vm.former.setAttr('recipient', _vm.form.recipient)},"blur":function($event){return _vm.touchField('form.recipient')}},model:{value:(_vm.form.recipient),callback:function ($$v) {_vm.$set(_vm.form, "recipient", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.recipient"}})],1)]),_c('div',{staticClass:"app__form-row"},[_c('div',{staticClass:"app__form-field"},[_c('textarea-field',{attrs:{"name":"transfer-description","label":_vm._f("globalize")('transfer-form.subject-lbl',{
                length: 250
              }),"maxlength":250,"readonly":_vm.formMixin.isDisabled,"disabled":!_vm.balance},on:{"input":function($event){return _vm.former.setAttr('subject', _vm.form.subject)}},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})],1)]),(_vm.isFeesLoaded && _vm.formMixin.isConfirmationShown &&
            _vm.fees.isAny
          )?_c('div',{staticClass:"transfer__fee-box"},[_c('fees-renderer',{attrs:{"fees-collection":_vm.fees,"paid-for-destination":_vm.form.isPaidFeeForRecipient},on:{"update:paidForDestination":function($event){return _vm.$set(_vm.form, "isPaidFeeForRecipient", $event)},"update:paid-for-destination":function($event){return _vm.$set(_vm.form, "isPaidFeeForRecipient", $event)}}})],1):_vm._e(),_c('div',{staticClass:"app__form-actions"},[(!_vm.formMixin.isConfirmationShown)?_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__form-submit-btn app__button-raised",attrs:{"type":"submit","disabled":_vm.formMixin.isDisabled || !_vm.balance,"form":"transfer-form"}},[_vm._v("\n            "+_vm._s(_vm._f("globalize")('transfer-form.continue-btn'))+"\n          ")]):_c('form-confirmation',{attrs:{"message":_vm._f("globalize")('transfer-form.recheck-form'),"ok-button":_vm._f("globalize")('transfer-form.submit-btn')},on:{"cancel":function($event){return _vm.hideConfirmation()},"ok":function($event){return _vm.submit(_vm.form.isPaidFeeForRecipient)}}})],1)])]]:(!_vm.isLoadingFailed)?[_c('transfer-form-skeleton-loader')]:[_c('p',[_vm._v("\n      "+_vm._s(_vm._f("globalize")('transfer-form.loading-error-msg'))+"\n    ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }