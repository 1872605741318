var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"amount-input-field"},[_c('input-field',_vm._g(_vm._b({attrs:{"name":"transfer-amount","type":"number","value":_vm.value,"autocomplete":"off","label":_vm.label,"min":_vm.minAmount,"max":_vm.maxAmount,"step":_vm.step,"error-message":_vm.getFieldErrorMessage('value', {
      from: {
        value: _vm.minAmount,
        currency: _vm.assetRecord.code,
      },
      to: {
        value: _vm.maxAmount,
        currency: _vm.assetRecord.code,
      },
    })},on:{"blur":function($event){return _vm.touchField('value')}}},'input-field',_vm.$attrs,false),_vm.listeners)),(_vm.isMaxButtonShown)?_c('button',{staticClass:"amount-input-field__max-btn",attrs:{"type":"button","disabled":_vm.$attrs.disabled || _vm.$attrs.readonly},on:{"click":function($event){return _vm.$emit(_vm.EVENTS.input, _vm.maxAmount)}}},[_c('i',{staticClass:"mdi mdi-arrow-up-bold amount-input-field__max-icon"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }