export const DOCUMENT_TYPES = Object.freeze({
  saleLogo: 'sale_logo',
  saleDocument: 'sale_document',
  kycIdDocument: 'kyc_id_document',
  kycProofOfAddress: 'kyc_poa',
  kycSelfie: 'kyc_selfie',
  kycAvatar: 'kyc_avatar',
  kycTaxReturns: 'kyc_tax_returns',
  kycOriginationCertificate: 'company_origination_certificate',
  kycInvestmentPresentation: 'kyc_investment_presentation',
  kycProofOfInvestor: 'kyc_proof_investor',
  kycShareholdersCertificate: 'kyc_shaholders_certificate',
  kycAnnualReport: 'kyc_annual_report',
  kycMemorandium: 'kyc_memorandium',
  kycSignatoriesAuthorization: 'kyc_signatories_authorization',
  kycOrganizationChart: 'kyc_organization_chart',
  kycBusinessModel: 'kyc_business_model',
  passport: 'passport',
  driving_license: 'driving_license',
  identity_card: 'identity_card',
  residence_permit: 'residence_permit',
  assetTerms: 'token_terms',
  assetLogo: 'asset_logo',
  healthcareDocument: 'healthcare_document',
})

export const DOCUMENT_TYPES_TRANSLATION_IDS = Object.freeze({
  [DOCUMENT_TYPES.saleLogo]: 'document-types.sale-logo',
  [DOCUMENT_TYPES.saleDocument]: 'document-types.sale-document',
  [DOCUMENT_TYPES.assetTerms]: 'document-types.asset-terms',
  [DOCUMENT_TYPES.assetLogo]: 'document-types.asset-logo',
  [DOCUMENT_TYPES.kycIdDocument]: 'document-types.kyc-id-document',
  [DOCUMENT_TYPES.kycProofOfAddress]: 'document-types.kyc-proof-of-address',
  [DOCUMENT_TYPES.kycSelfie]: 'document-types.kyc-selfie',
  [DOCUMENT_TYPES.kycAvatar]: 'document-types.kyc-avatar',
  [DOCUMENT_TYPES.kycTaxReturns]: 'document-types.kyc-tax-returns',
  [DOCUMENT_TYPES.kycOriginationCertificate]: 'document-types.kyc-origination-certificate',
  [DOCUMENT_TYPES.kycInvestmentPresentation]: 'document-types.kyc-investment-presentation',
  [DOCUMENT_TYPES.kycProofOfInvestor]: 'document-types.kyc-proof-of-investor',
  [DOCUMENT_TYPES.kycShareholdersCertificate]: 'document-types.kyc-shareholders-certificate',
  [DOCUMENT_TYPES.kycAnnualReport]: 'document-types.kyc-annual-report',
  [DOCUMENT_TYPES.kycMemorandium]: 'document-types.kyc-memorandum',
  [DOCUMENT_TYPES.kycSignatoriesAuthorization]: 'document-types.kyc-signatories-authorization',
  [DOCUMENT_TYPES.kycOrganizationChart]: 'document-types.kyc-organization-chart',
  [DOCUMENT_TYPES.kycBusinessModel]: 'document-types.kyc-business-model',
  [DOCUMENT_TYPES.passport]: 'document-types.passport',
  [DOCUMENT_TYPES.driving_license]: 'document-types.driving-license',
  [DOCUMENT_TYPES.identity_card]: 'document-types.identity-card',
  [DOCUMENT_TYPES.residence_permit]: 'document-types.residence-permit',
  [DOCUMENT_TYPES.assetTerms]: 'document-types.asset-terms',
  [DOCUMENT_TYPES.assetLogo]: 'document-types.asset-logo',
})
