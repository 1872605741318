var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trade-top-bar"},[[_c('top-bar',[_c('template',{slot:"main"},[(_vm.isLoaded)?_c('router-link',{attrs:{"to":{
            name: _vm.vueRoutes.tradeExchange.name,
            query: { base: _vm.assetPair.base, quote: _vm.assetPair.quote }
          }}},[_c('span',[_vm._v("\n            "+_vm._s(_vm._f("globalize")('trade-top-bar.exchange-view'))+"\n          ")])]):_c('skeleton-loader',{attrs:{"template":"smallString"}}),(_vm.isLoaded)?_c('router-link',{attrs:{"to":{
            name: _vm.vueRoutes.tradeUserOffers.name,
            query: { base: _vm.assetPair.base, quote: _vm.assetPair.quote }
          }}},[_c('span',[_vm._v("\n            "+_vm._s(_vm._f("globalize")('trade-top-bar.my-offers-view'))+"\n          ")])]):_c('skeleton-loader',{attrs:{"template":"smallString"}})],1),_c('template',{slot:"extra"},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised",on:{"click":function($event){_vm.isCreateBuyOfferDrawerShown = true}}},[_vm._v("\n          "+_vm._s(_vm._f("globalize")('trade-top-bar.create-buy-offer-button'))+"\n        ")]),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"app__button-raised",on:{"click":function($event){_vm.isCreateSellOfferDrawerShown = true}}},[_vm._v("\n          "+_vm._s(_vm._f("globalize")('trade-top-bar.create-sell-offer-button'))+"\n        ")])])],2),_c('div',{staticClass:"trade-asset-selector__wrapper"},[(_vm.formattedPairs.length && _vm.isLoaded)?_c('select-field',{key:_vm.selectedPair,staticClass:"trade-asset-selector__field app__select app__select--no-border",model:{value:(_vm.selectedPair),callback:function ($$v) {_vm.selectedPair=$$v},expression:"selectedPair"}},_vm._l((_vm.formattedPairs),function(assetPair){return _c('option',{key:assetPair,domProps:{"value":assetPair}},[_vm._v("\n          "+_vm._s(assetPair)+"\n        ")])}),0):(!_vm.isLoaded)?_c('skeleton-loader',{attrs:{"template":"bigString"}}):_c('no-data-message',{attrs:{"title":_vm._f("globalize")('trade-top-bar.no-pairs-message'),"message":_vm._f("globalize")('trade-top-bar.here-will-pairs-list')}})],1),_c('div',{staticClass:"trade-asset-selector__balances"},[(_vm.formattedPairs.length && _vm.isLoaded)?_c('p',{staticClass:"trade-asset-selector__balances-value"},[_vm._v("\n        "+_vm._s(_vm._f("formatMoney")(// eslint-disable-next-line
          { value: _vm.assetPairBalances.base, currency: _vm.assetPair.base }))+"\n        /\n        "+_vm._s(_vm._f("formatMoney")(// eslint-disable-next-line
          { value: _vm.assetPairBalances.quote, currency: _vm.assetPair.quote }))+"\n      ")]):(!_vm.isLoaded)?_c('skeleton-loader',{attrs:{"template":"bigString"}}):_vm._e(),(_vm.formattedPairs.length && _vm.isLoaded)?_c('p',{staticClass:"trade-asset-selector__balances-label"},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('trade-top-bar.user-balances-label'))+"\n      ")]):(!_vm.isLoaded)?_c('skeleton-loader',{staticClass:"trade-asset-selector__balances-skeleton-loader--margin",attrs:{"template":"smallString"}}):_vm._e()],1),_c('drawer',{attrs:{"is-shown":_vm.isCreateBuyOfferDrawerShown},on:{"update:isShown":function($event){_vm.isCreateBuyOfferDrawerShown=$event},"update:is-shown":function($event){_vm.isCreateBuyOfferDrawerShown=$event}}},[_c('template',{slot:"heading"},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('trade-top-bar.create-buy-offer-form-title'))+"\n      ")]),_c('create-trade-offer-form',{attrs:{"is-buy":"","asset-pair":_vm.assetPair},on:{"offer-created":_vm.closeBuyOfferDrawer}})],2),_c('drawer',{attrs:{"is-shown":_vm.isCreateSellOfferDrawerShown},on:{"update:isShown":function($event){_vm.isCreateSellOfferDrawerShown=$event},"update:is-shown":function($event){_vm.isCreateSellOfferDrawerShown=$event}}},[_c('template',{slot:"heading"},[_vm._v("\n        "+_vm._s(_vm._f("globalize")('trade-top-bar.create-sell-offer-form-title'))+"\n      ")]),_c('create-trade-offer-form',{attrs:{"asset-pair":_vm.assetPair},on:{"offer-created":_vm.closeSellOfferDrawer}})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }